<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import Player from "../../components/Player.vue";

const cams = ref(["player-id1", "player-id2", "player-id3"]);
let index = 0;

enterFalseFullScreen();

// Hide all elements except the player
function enterFalseFullScreen() {
    document.getElementById("app").firstElementChild.classList.add("layout-static-sidebar-inactive");
    document.querySelector("nav.layout-topbar").style.display = "none";
    document.querySelector("footer.layout-footer").style.display = "none";
}

// Display all the elements of the page
function leaveFalseFullScreen() {
    document.querySelector("nav.layout-topbar").style.display = "flex";
    document.querySelector("footer.layout-footer").style.display = "flex";
}

// Access players' functions by referencing them
const p1 = ref(null);
const p2 = ref(null);
const p3 = ref(null);

const players = [p1, p2, p3];

// Display only one player at a time
function setPlayers() {
    document.getElementById(cams.value[index]).parentNode.style.display = "block";
    for (let i = 0; i < 3; i++) {
        const cam = document.getElementById(cams.value[i]);
        if (i != index) {
            cam.parentNode.style.display = "none";
        }
    }
}

// Get the same button of the 3 players to iterate them
function getButtons() {
    const previousButtons = document.querySelectorAll("button.op-button.op-previous-cam-button");
    const nextButtons = document.querySelectorAll("button.op-button.op-next-cam-button");
    const soundButtons = document.querySelectorAll("button.op-button.op-volume-button");
    return { previousButtons, nextButtons, soundButtons };
}

// Set fullscreen with support on all the browsers
// (it may be deprecated in the future if fullscreen is removed)
function setFullScreen(index) {
    const element = document.getElementById(cams.value[index]);
    const requestFullScreen = element.requestFullscreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
    requestFullScreen.call(element);
}

onMounted(() => {
    setPlayers();

    let { previousButtons, nextButtons, soundButtons } = getButtons();

    // Change cams
    document.body.addEventListener("click", function (event) {
        for (let i = 0; i < previousButtons.length; i++) {
            // Change to previous cam
            if (previousButtons[i].contains(event.target)) {
                index = index - 1;
                if (index < 0) {
                    index = 2;
                }
                setPlayers();
                if (window.innerHeight == screen.height) {
                    document.exitFullscreen();
                    setTimeout(() => setFullScreen(index), 500);
                }
                break;

                // Change to next cam
            } else if (nextButtons[i].contains(event.target)) {
                index = index + 1;
                if (index > 2) {
                    index = 0;
                }
                setPlayers();
                if (window.innerHeight == screen.height) {
                    document.exitFullscreen();
                    setTimeout(() => setFullScreen(index), 500);
                }
                break;
            }
        }
    });

    // Mute or unmute all players at once
    document.body.addEventListener("click", function (event) {
        for (let i = 0; i < soundButtons.length; i++) {
            if (soundButtons[i].contains(event.target)) {
                let isMute = players[i].value.getMute();
                for (let n = 0; n < soundButtons.length; n++) {
                    players[n].value.setMute(isMute);
                }
                break;
            }
        }
    });
});

onUnmounted(() => {
    leaveFalseFullScreen();
});
</script>

<template>
    <main>
        <!-- <Player :id="cams[0]" text="ptz" :sources="[{ type: 'webrtc', file: 'wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-ptz' }]" />
        <Player :id="cams[1]" text="fixed" :sources="[{ type: 'webrtc', file: 'wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-fixed' }]" />
        <Player :id="cams[2]" text="desktop" :sources="[{ type: 'webrtc', file: 'wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-desktop' }]" /> -->
        <!-- <Player :id="cams[0]" text="ptz" :sources="[{ type: 'webrtc', file: 'wss://cide.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-ptz' }]" ref="p1" v-on:ready="onReady" /> -->
        <Player :id="cams[0]" ref="p1" text="ptz" :sources="[{ type: 'webrtc', file: 'wss://cide.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-ptz' }]" />
        <Player :id="cams[1]" ref="p2" text="fixed" :sources="[{ type: 'webrtc', file: 'wss://cide.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-fixed' }]" />
        <Player :id="cams[2]" ref="p3" text="desktop" :sources="[{ type: 'webrtc', file: 'wss://cide.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-desktop' }]" />
    </main>
</template>

<style scoped>
.player-wrapper {
    background-color: black;
}
</style>
